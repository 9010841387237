@import '../../../assets/scss/variables.scss';

.social-media-container {
    position: fixed;
    left: 0;
    top: 50%;
    display: flex;
    transform: translateY(-50%);
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    background: #FFF;
    padding: 10px;
    height: 140px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: all 0.3s;
    box-shadow: -1px 0px 8px 0px rgba(0, 0, 0, 0.35);

    i {
        color: $primary;
        font-size: 18px;

        &:hover {
            opacity: 0.4;
        }
    }

    a {
        text-decoration: none !important;
    }

    &:hover {
        height: 160px;
        padding: 15px;
    }
}

@media only screen and (max-width: #{$breakpoint-sm}) {
    .social-media-container {
        height: 160px;

        i {
            font-size: 24px;
        }
    }
}
