.sell-car-wrapper {
    margin-top: 80px;
}

.car-details {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: 1.8px;
}

.car-info-container {
    padding: 0 10px;
}
.car-info-container,
.car-info {
    width: 100%;
}

.accident-free {
    color:#aaa3a3;
    font-size: 14px;
    margin-bottom: 10px;
}

.accident-option {
    margin: 0 10px;
}

.car-info {
    border-width: 0px 0px 1px 0px;
    border-radius: 0px 0px 0px 0px;
    border-color: #C7C7C7;
    font-size: 14px;
    padding: 12px 16px;

    &::placeholder {
        color:#aaa3a3;
    }

    &:focus {
        border-color: lightblue;
        // box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%)
    }
}

label {
    color:#aaa3a3;
}

.more-info {
    margin: 30px 0;

    .name-label {
        margin-bottom: 20px;
    }

    .custom-file {
        height: auto;
    }
}

.send-button .animation-wrapper {
    width: 150px;
}