@import '../../../assets/scss/variables.scss';

.card-grid-wrapper {
    margin-top: 80px;

    .card {
        border: 1px $cod-gray dashed;
        height: 570px;

        .card-title {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .card-img-top {
            transition: filter 0.2s ease-in-out;
            height: 250px;
            object-fit: cover;
        }

        .card-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &:hover {
            .card-img-top {
                filter: brightness(1.1);
            }

            .custom-btn {
                background-color: $cod-gray;
                color: $white;
            }
        }
    }

    .card-link {
        color: $black;

        &:hover {
            .card-title {
                text-decoration: none;
            }
        }
    }
}

.cards-grid-carousel {
    margin-bottom: 30px;
    user-select: none;

    .carousel-item {
        img {
            height: 270px;
            object-fit: cover;
        }
    }

    .carousel-indicators,
    .carousel-control-next,
    .carousel-control-prev {
        display: none;
    }

    .carousel-caption {
        bottom: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &::before {
            content: '';
            background: $white;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.9;
            z-index: -1;
        }

        h3 {
            font-size: 20px;
            border-top-right-radius: 20px;
            margin-bottom: 0;
        }

        p {
            border-bottom-left-radius: 20px;
        }

        h3,
        p {
            color: #000;
            z-index: 1;
        }
    }
}

.cars-page-title {
    position: relative;

    &::after {
        background-image: url('../../../assets/svg/curved-line.svg');
        @include CurvedLine(-25px);
        filter: invert(1);
        bottom: -10px;
    }
}