@import '../../../assets/scss/variables.scss';

.service-title {
    margin-top: 80px;
    color: $black;
    text-align: center;
    margin-bottom: 70px;
    text-transform: uppercase;
    font-size: 35px;
    font-weight: bold;
    position: relative;

    &::after {
        background-image: url('../../../assets/svg/curved-line.svg');
        @include CurvedLine(-25px);
        filter: invert(1);
    }
}