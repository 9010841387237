@import '../../assets/scss/variables.scss';
@import '../../assets/scss/transitions.scss';

@mixin header-bg {
    content: '';
    background-image: url('../../assets/img/header-bg.png');
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    bottom: 0px;
    transform: rotate(180deg);
    z-index: 1;
}

.navbar.navbar-light {
    background-color: $white;
    position: fixed;
    width: 100%;
    box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.1);
    top: 0;
    z-index: 2;

    &::before {
        @include header-bg;
    }

    &::after {
        @include header-bg;
        bottom: -19px;
        transform: rotate(0);
    }

    .navbar-brand {
        img {
            animation-duration: 1s;
            width: 200px;
        }
    }

    .navbar-nav {
        .nav-link {
            line-height: 72px;
            margin: -8px 0;
            transition: all 0.3s ease;
            position: relative;
        }

        .active,
        .nav-link:hover {
            background-color: $primary;
            color: $white;
        }
    }
}

.navbar-collapse  {
    animation-duration: 1s;
    animation-delay: 0.6s;
}

.dropdown-item.active,
.dropdown-item:hover,
.dropdown-item:active {
    background-color: $primary !important;
    color: $white;
}

.dropdown-menu.show {
    top: 100px;
}

.navbar-items {
    justify-content: flex-end;
}

@media only screen and (max-width: #{$breakpoint-lg}) {
    .navbar.navbar-light {
        position: relative;

        .navbar-brand {
            padding-left: 0;
            margin-left: -20px;
        }

        .navbar-nav {
            .nav-link {
                line-height: 1;
                margin: 0;
            }

            .active,
            .nav-link:hover {
                background-color: $white;
                color: $cod-gray;
            }
        }
    }

    .navbar-toggler {
        position: absolute;
        right: 30px;
        top: 20px;
    }
}