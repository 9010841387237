@import '../../../assets/scss/variables.scss';

.animation-wrapper {
    width: 100%;
}

.newsletter-wrapper {
    background-color: $primary;
    position: relative;
    padding: 80px 0;

    &::after {
        content: '';
        background-image: url('../../../assets/img/header-bg.png');
        position: absolute;
        width: 100%;
        height: 20px;
        left: 0;
        bottom: 0px;
        transform: rotate(180deg);
        z-index: 1;
    }

    &::before {
        content: '';
        background-image: url('../../../assets/img/header-bg.png');
        position: absolute;
        width: 100%;
        height: 20px;
        left: 0;
        top: 0px;
        z-index: 1;
    }
}

.fields-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
        display: flex;
        flex-direction: column;
    }

    h1 {
        margin-bottom: 80px;
        position: relative;

        &::after {
            background-image: url('../../../assets/svg/curved-line.svg');
            @include CurvedLine(-25px);
            filter: invert(1);
        }
    }

    .name-field,
    .email-field {
        border: none;
        height: 48px;
        background: transparent;
        font-weight: bold;
        border-bottom: $cod-gray dotted 3px;
        transition: padding 0.5s ease, background-color 0.2s ease-in;

        &:focus {
            background: $white;
            color: $black;
            padding-left: 20px;

            &::placeholder {
                color: $black;
            }
        }

        &::placeholder {
            color: $white;
            opacity: 0.5;
        }
    }

    .email-field,
    .custom-btn {
        margin-top: 10px;
    }

    .custom-btn:focus {
        outline: 0;
    }
}

input#check {
	position: absolute;
	opacity: 0;

	&:checked + label svg path {
		stroke-dashoffset: 0;
	}

	&:focus + label {
		transform: scale(1.03);
	}
}

#check + label {
	display: block;
	border: 13px solid #333;
	width: var(--d); height: var(--d);
	border-radius: 14px;
	cursor: pointer;
	transition: all .2s ease;

	&:active {
		transform: scale(1.05);
		border-radius: 30px;
	}

	svg {
        pointer-events: none;

		path {
			fill: none;
			stroke: #333;
			stroke-width: 4px;
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-dasharray: 100;
			stroke-dashoffset: 101;
			transition: all 350ms cubic-bezier(1,0,.37,.91);
		}
	}
}

.terms-checkbox-input {
    display:none;
}

.terms-checkbox {
    display: flex;
    margin-top: 20px;
    font-size: 12px;

    .terms-text a {
        color: $black;
        text-decoration: underline;
    }

    div {
        width: 23px;
        height: 23px;
        display: inline-block;
        border: 2px solid #fff;
        text-align: center;
        line-height: 20px;
        margin-right: 6px;
        border-radius: 3px;
        cursor: pointer;
    }
}

.terms-checkbox i {
    font-size: 16px;
    opacity: 0;
    color: $white
}

.terms-checkbox:hover div {
    background:#16a085;
}

input:checked + .terms-checkbox i {
    opacity: 1;
}
input:checked + .terms-checkbox div {
    background: #16a085;
}