@import '../../../assets/scss/variables.scss';

.contact-form-wrapper {
    margin-top: 80px;

    .success-alert {
        background-color: rgb(0, 255, 0);
        color: white;
        border: none;
    }
}

.email-sent-animation {
    height: 160px;
    margin: -30px 0;
}

.contact-form-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fade-in-delay {
    animation-delay: 0.6s;
}

.map_area {
    margin-top: 60px;
    width: 100%;
    height: auto;
    position: relative;

    img {
        width: 100%;
        height: 100%;
    }

    .map-pin {
        position: absolute;
        width: 40px;
        left: 49%;
        top: 130px;
        height: 40px;
    }

    .map-pin-tooltip {
        user-select: none;
        left: 42%;
        top: 180px;
        position: absolute;
        width: 200px;
        text-align: center;
        background: $black;
        color: $white;
        font-size: 12px;
        border-radius: 5px;
        padding: 5px;
        cursor: pointer;
    }

    .get-directions-text {
        margin-top: 10px;
        display: inline-block;
        text-decoration: underline;
        font-weight: bold;
    }
}

@media only screen and (max-width: #{$breakpoint-lg}) {
    .map_area {
        .map-pin {
            top: 40px;
        }

        .map-pin-tooltip {
            top: 90px;
        }
    }
}
