.financing-offer-wrapper {
    margin-top: 80px;
}

.personal-data,
.residence-data {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: 1.8px;
    margin: 50px 0;
}

.required-star {
    color: red;
}

.person-title,
.name-label {
    color:#aaa3a3;
    font-size: 14px;
    margin: 20px 0 10px;
}

.person-title-options {
    display: flex;
    margin-bottom: 20px;
}

.person-title-opion {
    margin-right: 20px;

    label {
        padding-left: 10px;
        color:#aaa3a3;
    }
}

.personal-info-container {
    width: 300px;
}

.name-label {
    margin-bottom: 0;
}

.personal-info {
    border-width: 0px 0px 1px 0px;
    border-radius: 0px 0px 0px 0px;
    border-color: #C7C7C7;
    font-size: 14px;
    padding: 12px 16px;
    width: 100%;

    &::placeholder {
        color:#aaa3a3;
    }

    &:focus {
        border-color: lightblue;
        // box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%)
    }
}

.other-nationalities {
    margin-top: 30px;
}
