/**
 * ----------------------------------------
 * animation slide-in-blurred-left
 * ----------------------------------------
 */
.slide-in-blurred-left {
    animation: roll-in-blurred-left 0.65s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

@keyframes roll-in-blurred-left {
    0% {
        transform: translateX(-1000px);
        filter: blur(50px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        filter: blur(0);
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
.fade-in {
    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation rotate-in-center
 * ----------------------------------------
 */
.rotate-in-center {
    animation: rotate-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes rotate-in-center {
    0% {
        transform: rotate(-360deg);
        opacity: 0;
    }

    100% {
        transform: rotate(0);
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
.scale-in-center {
    animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes scale-in-center {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
.slide-in-bottom {
    animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-bottom {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}


/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
.wobble-hor-bottom {
    animation: wobble-hor-bottom 1.3s both infinite;
}

@keyframes wobble-hor-bottom {

    0%,
    100% {
        transform: translateX(0%);
        transform-origin: 50% 50%;
    }

    15% {
        transform: translateX(-5px) rotate(-6deg);
    }

    30% {
        transform: translateX(5px) rotate(6deg);
    }

    45% {
        transform: translateX(-5px) rotate(-3.6deg);
    }

    60% {
        transform: translateX(5px) rotate(2.4deg);
    }

    75% {
        transform: translateX(-5px) rotate(-1.2deg);
    }
}

/**
 * ----------------------------------------
 * animation flip-in-hor-top
 * ----------------------------------------
 */
.flip-in-hor-top {
    animation: flip-in-hor-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes flip-in-hor-top {
    0% {
        transform: rotateX(-80deg);
        opacity: 0;
    }

    100% {
        transform: rotateX(0);
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
.text-focus-in {
    animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@keyframes text-focus-in {
    0% {
        filter: blur(12px);
        opacity: 0;
    }

    100% {
        filter: blur(0px);
        opacity: 1;
    }
}