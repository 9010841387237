@import '../../../assets/scss/variables.scss';

.footer-logo {
    background-image: url('../../../assets/svg/logo.svg');
    width: 63px;
    height: 45px;
    background-repeat: no-repeat;
    background-size: 254px;
    background-position: -20px;
    margin: 0 auto;
    transition: all 0.4s ease;

    &:hover {
        width: 250px;
        background-position: center;
    }
}

.copyright-years {
    text-align: center;
}

.footer-logo-container {
    border-bottom: 1px dotted $light-grey;
    margin-bottom: 20px;
}

.footer-content {
    li {
        font-size: 14px;
    }
}

.fixed-bottom-nav {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
}

.social-media-container {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: #{$breakpoint-md}) {
    .fixed-bottom-nav {
        position: relative;
    }
}
