@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;700&display=swap');

@import "~bootstrap/scss/bootstrap";
@import "./variables.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

body {
    padding-top: 80px;
    font-family: 'Montserrat', sans-serif;
}

.custom-btn {
    font-weight: bold;
    font-size: 14px;
    border: 2px solid $cod-gray;
    width: 100%;
    display: block;
    text-align: center;
    padding: 15px 0;
    color: $cod-gray;
    transition: background-color 0.2s;
    text-transform: uppercase;
    line-height: 1;
    background-color: $white;

    &:hover {
        text-decoration: none;
        background-color: $cod-gray;
        color: $white;
    }
}

.btn-enz {
    background-color: $primary;
    color: $white;
}

.stick-nav-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.card {
    .card-title {
        font-weight: 600;
    }

    .specs-info {
        .specs-info__unit {
            margin-left: 2px;
            font-size: 12px;
            color: $cod-gray;
        }
    }
}

.divider {
    position: relative;
    margin: 10px;

    &::after {
        background-image: url('../svg/curved-line.svg');
        @include CurvedLine(-25px);
        filter: invert(1);
        bottom: -10px;
    }
}

.custom-dropdown {
    width: 100%;
    padding: 10px;
}

@media only screen and (max-width: #{$breakpoint-lg}) {
    body {
        padding-top: 0;
    }
}
