@import './variables.scss';

.home-page-slider {
    .slide-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        content-visibility: auto;
    }

    .card-container {
        height: 834px;
        position: relative;
    }

    .slick-arrow.slick-prev,
    .slick-arrow.slick-next,
    .slick-dots {
        display: none !important;
    }

    .slick-slide > div  {
        position: relative;
    }

    .slider-text {
        left: 25%;
        top: 50%;
        position: absolute;
        transform: translate(-25%, -50%);
        z-index: 2;
        color: $white;

        h2 {
            font-weight: 200;
        }

        h1 {
            font-weight: 600;
        }
    }

    .slide-image::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
    }

    .slick-track {
        height: 834px;

        &::after {
            background-image: url('../../assets/img/header-bg.png');
            content: '';
            position: absolute;
            bottom: 0px;
            width: 100%;
            height: 20px;
            transform: rotate(180deg);
            filter: invert(1);
        }
    }
}

@media only screen and (max-width: #{$breakpoint-lg}) {
.home-page-slider {
        .card-container,
        .slick-track {
            height: 600px;
        }

        .slider-text {
            h2 {
                font-size: 24px;
            }

            h1 {
                font-size: 30px;
            }
        }
    }
}
