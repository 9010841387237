@import '../../../../assets/scss/variables.scss';

.timeline {
    position: relative;

    @media (max-width: 991px) {
        padding-top: 50px;
    }

    &:before {
        content: "";
        width: 2px;
        height: 100%;
        margin: 0 auto;
        background-color: $primary;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        @media (max-width: 991px) {
            left: 120px;
            right: 0;
            margin: 0;
        }

        @media (max-width: 776px) {
            left: 80px;
        }
    }

    &__row {
        width: 100%;
        display: inline-block;
        margin-bottom: 50px;
        position: relative;

        @media (max-width: 991px) {
            margin-bottom: 70px;
        }

        @media (max-width: 776px) {
            margin-bottom: 70px;
        }

        &::before {
            content: "";
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 3px solid $white;
            background-color: $primary;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%);

            @media (max-width: 991px) {
                top: 0;
                left: 122px;
                right: 0;
                margin: 0;
            }

            @media (max-width: 776px) {
                left: 83px;
            }
        }
    }

    &__date {
        display: inline-block;
        background-color: $primary;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        line-height: 100px;
        text-align: center;
        position: absolute;
        font-size: 2.5rem;
        font-weight: bold;
        color: $white;
        top: 50%;
        left: 20%;
        transform: translateY(-50%);

        @media (max-width: 1199px) {
            left: 14%;
        }

        @media (max-width: 991px) {
            width: 60px;
            height: 60px;
            line-height: 60px;
            top: 0;
            left: 0;
            font-size: 1.8rem;
        }

        &::after,
        &::before {
            content: "";
            width: 200px;
            height: 2px;
            background-color: $primary;
            position: absolute;
            top: 50%;
            right: -180px;
            transform: translateY(-50%);
            z-index: -1;
        }

        &::before {
            @media (max-width: 1200px) {
                width: 50px;
                right: -50px;
            }

            @media (max-width: 991px) {
                width: 75px;
                left: auto;
                right: -60px;
            }

            @media (max-width: 776px) {
                width: 25px;
                right: -25px;
            }
        }

        &::after {
            @media (max-width: 991px) {
                display: none;
            }
        }
    }

    &__content {
        width: 50%;
        padding-left: 40px;
        margin: 52px 0 0;
        float: right;
        position: relative;

        @media (max-width: 1200px) {
            margin-top: 75px;
        }

        @media (max-width: 991px) {
            width: 100%;
            margin-top: -15px;
            padding-left: 160px;
            padding-right: 5px;
        }

        @media (max-width: 776px) {
            padding-left: 130px;
        }

        &::before {
            @media (max-width: 991px) {
                width: 50%;
                left: 120px;
            }
        }

        &::after {
            @media (max-width: 991px) {
                left: 85px;
            }
        }
    }

    &__row:nth-child(even) {
        .timeline__date {
            left: auto;
            right: 180px;

            @media (max-width: 1199px) {
                right: 100px;
            }

            @media (max-width: 991px) {
                left: 0;
                right: auto;
            }

            &::before {
                right: auto;
                left: -180px;

                @media (max-width: 1200px) {
                    right: auto;
                    left: -50px;
                }

                @media (max-width: 991px) {
                    width: 75px;
                    left: auto;
                    right: -60px;
                }

                @media (max-width: 776px) {
                    width: 25px;
                    right: -25px;
                }
            }

            &:after {
                right: auto;
                left: -180px;

                @media (max-width: 991px) {
                    display: none;
                }
            }
        }

        .timeline__content {
            float: left;
            text-align: right;
            padding-right: 40px;

            @media (max-width: 991px) {
                float: right;
                text-align: left;
            }

            @media (max-width: 776px) {
                padding-left: 130px;
            }

            &::before {
                left: auto;
                right: 35px;
                transform: rotateY(180deg);

                @media (max-width: 991px) {
                    width: 50%;
                    left: 120px;
                    right: auto;
                    transform: rotateY(0);
                }
            }

            &::after {
                left: auto;
                right: 0;
            }
        }
    }

    .timeline__content {
        h2 {
            font-size: 1.6rem;
            word-break: break-all;
        }

        p {
            color: $light-grey;
        }
    }
}