@import '../../../assets/scss/variables.scss';

.recent-cars-section {
    background-color: $black;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;

    .card {
        border: none;
        height: 570px;

        .card-title {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .card-img-top {
            transition: filter 0.2s ease-in-out;
            height: 250px;
            object-fit: cover;
        }

        .card-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &:hover {
            .card-img-top {
                filter: brightness(1.2);
            }

            .custom-btn {
                background-color: $cod-gray;
                color: $white;
            }
        }

        &::after {
            content: '';
            background-image: url('../../../assets/img/header-bg.png');
            position: absolute;
            width: 100%;
            height: 20px;
            left: 0;
            bottom: 0px;
            transform: rotate(180deg);
            z-index: 1;
        }
    }

    &::after {
        content: '';
        background-image: url('../../../assets/img/header-bg.png');
        position: absolute;
        width: 100%;
        height: 20px;
        left: 0;
        bottom: -19px;
        transform: rotate(0deg);
        z-index: 1;
        filter: invert(1);
    }
}

.recent-cars-title {
    color: $white;
    text-align: center;
    margin-bottom: 70px;
    text-transform: uppercase;
    font-size: 35px;
    font-weight: bold;
    position: relative;

    &::after {
        background-image: url('../../../assets/svg/curved-line.svg');
        @include CurvedLine(-25px);
    }
}

.car-specs {
    margin-bottom: 24px;
    display: block;
}

@media only screen and (max-width: #{$breakpoint-lg}) {
    .card-wrapper {
        &:nth-child(n + 3) {
            margin-top: 29px;
        }
    }
}

@media only screen and (max-width: #{$breakpoint-md}) {
    .card-wrapper {
        &:nth-child(n + 3) {
            margin-top: 15px;
        }
    }
}

@media only screen and (max-width: #{$breakpoint-sm}) {
    .card-wrapper {
        margin-top: 15px;
    }
}