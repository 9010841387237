@import '../../../../assets/scss/variables.scss';

.car-details {
    margin-top: 80px;

    .car-title {
        font-size: 30px;
        font-weight: 600;
    }

    .car-price {
        color: $red;
    }

    .small-car-images {
        height: 125px;
        object-fit: cover;
    }

    .car-img {
        height: 480px;
        object-fit: cover;
    }

    .small-cars-slider {
        animation-delay: 1s;
    }

    .divider {
        height: 12px;

        &::after {
            bottom: 0;
        }
    }

    .custom-btn {
        width: 200px;

        .back-arrow {
            left: 0;
            position: relative;
            transition: left 100ms ease-in-out;
        }

        &:hover {
            .back-arrow {
                left: -10px;
            }
        }
    }
}

.wysiwyg--container * {
    word-break: break-all;
}
