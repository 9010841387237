// Colors
$white: #FFF;
$primary: #03ce86;
$black: #000;
$cod-gray: #0F0F0F;
$light-grey: #AFAFAF;
$red: #d90000;

// Mixins
/**
* Needs to define background-image on call
*/
@mixin CurvedLine($bottom) {
    background-repeat: no-repeat;
    content: '';
    height: 12px;
    left: 50%;
    bottom: $bottom;
    position: absolute;
    transform: translateX(-50%);
    width: 71px;
}

// Breakpoints
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;